import * as React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Loader from "./components/Loader";
import Header from "./components/Header";
import Demo from "./pages/Demo";
import Home from "./pages/Home";
import Home2 from "./pages/Home2";
import Home3 from "./pages/Home3";
import Home4 from "./pages/Home4";
import Home5 from "./pages/Home5";
import Home6 from "./pages/Home6";
import Home7 from "./pages/Home7";
import Home8 from "./pages/Home8";
import Home9 from "./pages/Home9";
import Home10 from "./pages/Home10";
import MoreAppsPage from "./pages/MoreAppsPage";
import Pricing from "./pages/Pricing";
import Reviews from "./pages/Reviews";
import Faqs from "./pages/Faqs";
import BlogListing from "./pages/BlogListing";
import SinglePost from "./pages/SinglePost";
import Terms from "./pages/Terms";
import Contacts from "./pages/Contacts";
import Features from "./pages/Features";
import Footer from "./components/Footer";
import Error from "./pages/Error";
import "./assets/css/bootstrap.min.css";
import "./assets/css/flaticon.css";
import "./assets/css/animate.css";
import "./assets/css/dropdown-effects/fade-down.css";
import "./assets/css/typography.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/js/custom.js";

//*** Add "homepage" in package.json ***//

//Ex:- "homepage": "https://codextheme.codexperts.in/descode"   ||   "homepage": "https://www.yourdomain.com/folder/descode"
//Ex:- <BrowserRouter basename='/descode/'>   ||   <BrowserRouter basename='/react/descode/'>

//*** basename = domain name after folder path. ***//
// Ex:- basename='/descode/'

class App extends React.Component {
  render() {
    return (
      <>
        <BrowserRouter basename="">
          {/* <Loader/> */}
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/Home" element={<Home />} />
            {/* <Route exact path="/Home2" element={<Home2/>} />
              <Route exact path="/Home3" element={<Home3/>} />
              <Route exact path="/Home4" element={<Home4/>} />
              <Route exact path="/Home5" element={<Home5/>} />
              <Route exact path="/Home6" element={<Home6/>} />
              <Route exact path="/Home7" element={<Home7/>} />
              <Route exact path="/Home8" element={<Home8/>} />
              <Route exact path="/Home9" element={<Home9/>} />
              <Route exact path="/Home10" element={<Home10/>} /> */}
            <Route exact path="/Faqs" element={<Faqs />} />
            <Route exact path="/Features" element={<Features />} />
            <Route exact path="/MoreAppsPage" element={<MoreAppsPage />} />
            <Route exact path="/Pricing" element={<Pricing />} />
            <Route exact path="/Reviews" element={<Reviews />} />
            <Route exact path="/SinglePost" element={<SinglePost />} />
            <Route exact path="/Terms" element={<Terms />} />
            <Route exact path="/BlogListing" element={<BlogListing />} />
            <Route exact path="/Contacts" element={<Contacts />} />
            <Route exact path="*" element={<Error />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </>
    );
  }
}

export default App;
