import React from "react";

class Terms extends React.Component {
  render() {
    return (
      <>
        {/* PAGE CONTENT */}
        <div id="page" className="page">
          {/* TERMS & PRIVACY */}
          <section
            id="terms-page"
            className="bg_whitesmoke hero-offset-nav pb-70 terms-section division"
          >
            <div className="container">
              <div className="row">
                {/* TERMS CONTENT */}
                <div className="col-lg-10 offset-lg-1">
                  {/* TERMS TITLE */}
                  <div className="terms-title text-center">
                    {/* Title */}
                    <h2 className="h2-md">Privacy Policy</h2>
                    {/* Text */}
                    <p className="p-xl grey-color">
                      Last Modified: April 03, 2023
                    </p>
                  </div>
                  {/* TERMS BOX */}
                  <div className="terms-box">
                    {/* Text */}
                    <p className="p-lg">
                      Capelex Pharmacy Ltd ("us", "we", or "our") operates the
                      Capelex Pharmacy : Medicine and Health Products Delivery
                      App (the "Service").
                    </p>
                    {/* Text */}
                    <p className="p-lg">
                      This page informs you of our policies regarding the
                      collection, use, and disclosure of personal data when you
                      use our Service and the choices you have associated with
                      that data. We use your data to provide and improve the
                      Service. By using the Service, you agree to the collection
                      and use of information in accordance with this policy.
                    </p>
                  </div>
                  {/* TERMS BOX */}
                  <div className="terms-box">
                    {/* Title */}
                    <h4 className="h4-sm">INFORMATION COLLECTION AND USE</h4>
                    {/* Text */}
                    <p className="p-lg">
                      Capelex Pharmacy collects information from users of our
                      mobile application in order to provide and improve our
                      delivery service. We take your privacy seriously and are
                      committed to protecting your personal information.
                    </p>
                    {/* Title */}
                    <h4 className="h4-sm">Types of Data Collected</h4>

                    {/* Title */}
                    <h4 className="h4-sm">Personal Data</h4>
                    {/* Text */}
                    <p className="p-lg">
                      While using our app, we may ask you to provide us with
                      certain personally identifiable information that can be
                      used to contact or identify you ("Personal Data").
                      Personally identifiable information may include, but is
                      not limited to:
                    </p>
                    {/* List */}
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg">
                          <span>Email address</span>
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">
                          <span>First name and Last name</span>{" "}
                        </p>
                      </li>
                    </ul>
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg">
                          <span>Phone number</span>
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">
                          <span>Delivery Address</span>{" "}
                        </p>
                      </li>
                    </ul>
                    {/* Text */}
                    <p className="p-lg">
                      We use this information to deliver your orders and provide
                      customer support.
                    </p>
                  </div>
                  {/* TERMS BOX */}
                  <div className="terms-box">
                    {/* Title */}
                    <h4 className="h4-sm">Usage Data</h4>
                    {/* Text */}
                    <p className="p-lg">
                      We may also collect information about how the app is
                      accessed and used ("Usage Data"). This Usage Data may
                      include information such as your device's Internet
                      Protocol (IP) address, device type, operating system, app
                      version, the pages of our app that you visit, the time and
                      date of your visit, and other diagnostic data. We use this
                      information to understand how users interact with our app
                      and to improve our services.
                    </p>
                  </div>
                  {/* TERMS BOX #3 */}
                  <div className="terms-box">
                    {/* Title */}
                    <h4 className="h4-sm">Location Data</h4>
                    {/* Text */}
                    <p className="p-lg">
                      We may collect and process information about your actual
                      location if you give us permission to do so through your
                      device's location settings. We use this information to
                      provide location-based services, such as delivery
                      tracking.
                    </p>
                  </div>
                  {/* TERMS BOX */}
                  <div className="terms-box">
                    {/* Title */}
                    <h4 className="h4-sm">Tracking & Cookies Data</h4>
                    {/* Text */}
                    <p className="p-lg">
                      We use cookies and similar tracking technologies to track
                      the activity on our app and hold certain information.
                    </p>
                    <p className="p-lg">
                      Cookies are files with small amount of data which may
                      include an anonymous unique identifier. Cookies are sent
                      to your browser from a website and stored on your device.
                      Tracking technologies also used are beacons, tags, and
                      scripts to collect and track information and to improve
                      and analyze our app.
                    </p>
                    <p className="p-lg">
                      You can instruct your device to refuse all cookies or to
                      indicate when a cookie is being sent. However, if you do
                      not accept cookies, you may not be able to use some
                      portions of our app.
                    </p>
                    <h4 className="h4-sm"></h4>
                  </div>
                  {/* TERMS BOX #5 */}
                  <div className="terms-box">
                    {/* Title */}
                    <h4 className="h4-sm">Use of Data</h4>
                    {/* Text */}
                    <p className="p-lg">
                      Aliqum mullam blandit tempor sapien gravida donec ipsum,
                      at porta justo. Velna vitae auctor congue magna nihil
                      impedit ligula risus. Mauris donec ociis et magnis sapien
                      sagittis sapien sem congue tempor gravida donec enim ipsum
                      porta justo integer odio velna a purus efficitur ipsum
                      primis in cubilia laoreet augue egestas luctus donec purus
                      and blandit sodales
                    </p>
                    {/* List */}
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg">
                          To provide and maintain our delivery service
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">
                          To notify you about changes to our service
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">
                          To allow you to participate in interactive features of
                          our app when you choose to do so
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">
                          To provide customer care and support
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">
                          To provide analysis or valuable information so that we
                          can improve the service
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">To monitor the usage of the app</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">
                          To detect, prevent and address technical issues
                        </p>
                      </li>
                    </ul>
                  </div>
                  {/* TERMS BOX */}
                  <div className="terms-box">
                    {/* Title */}
                    <h4 className="h4-sm">Disclosure of Data</h4>
                    <p className="p-lg">
                      Capelex Pharmacy does not sell or rent your personal
                      information to third parties. We may disclose your
                      personal information in the following circumstances:
                    </p>
                    {/* List */}
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg">
                          To our employees, contractors, and service providers
                          who need to know the information in order to provide
                          and improve our services.
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">
                          To comply with a legal obligation or protect the
                          rights, property, or safety of Capelex Pharmacy, our
                          customers, or others.
                        </p>
                      </li>
                    </ul>
                  </div>
                  {/* TERMS BOX */}
                  <div className="terms-box">
                    {/* Title */}
                    <h4 className="h4-sm">Security of Data</h4>
                    {/* Text */}
                    <p className="p-lg">
                      The security of your data is important to us, and we take
                      reasonable precautions to protect your personal
                      information. However, no method of transmission over the
                      internet or electronic storage is 100% secure, and we
                      cannot guarantee absolute security.
                    </p>
                  </div>
                  {/* TERMS BOX */}
                  <div className="terms-box">
                    {/* Title */}
                    <h4 className="h4-sm">Links To Other Sites</h4>
                    {/* Text */}
                    <p className="p-lg">
                      Our app may contain links to other sites that are not
                      operated by us. If you click on a third party link, you
                      will be directed to that third party's site. We strongly
                      advise you to review the privacy policy of every site you
                      visit.
                    </p>
                    {/* Text */}
                    <p className="p-lg">
                      We have no control over and assume no responsibility for
                      the content, privacy policies, or practices
                    </p>
                  </div>
                  {/* TERMS BOX #9 */}
                  <div className="terms-box">
                    {/* Title */}
                    <h4 className="h4-sm">Children's Privacy</h4>
                    {/* Text */}
                    <p className="p-lg">
                      Our Service is not intended for use by children under the
                      age of 13. We do not knowingly collect personal
                      information from children under 13. If you are a parent or
                      guardian and you believe your child has provided us with
                      personal information, please contact us. If we become
                      aware that we have collected personal information from a
                      child under 13 without parental consent, we will take
                      steps to remove the information from our servers.
                    </p>
                  </div>
                  {/* TERMS BOX */}
                  <div className="terms-box">
                    {/* Title */}
                    <h4 className="h4-sm">Changes to this Privacy Policy</h4>
                    {/* Text */}
                    <p className="p-lg">
                      We may update our Privacy Policy from time to time. We
                      will notify you of any changes by posting the new Privacy
                      Policy on this page.
                    </p>
                    {/* Text */}
                    <p className="p-lg">
                      You are advised to review this Privacy Policy periodically
                      for any changes. Changes to this Privacy Policy are
                      effective when they are posted on this page.
                    </p>
                  </div>
                  {/* TERMS BOX */}
                  <div className="terms-box">
                    {/* Title */}
                    <h4 className="h4-sm">Contact Us</h4>
                    {/* Text */}
                    <p className="p-lg">
                      If you have any questions or concerns about this Privacy
                      Policy, please contact us at
                      <a href="#/" className="skyblue-color">
                        {" "}
                        info@capelexpharmacy.co.tz.
                      </a>
                    </p>
                  </div>
                </div>{" "}
                {/* END TERMS CONTENT */}
              </div>
            </div>
            {/* GEOMETRIC OVERLAY */}
            <div className="bg_fixed geometric_overlay" />
          </section>

          {/* NEWSLETTER-1 */}
          <section
            id="newsletter-1"
            className="bg_whitesmoke pb-20 newsletter-section division"
          >
            <div className="container">
              <div className="newsletter-wrapper bg-white">
                <div className="row d-flex align-items-center">
                  {/* SECTION TITLE */}
                  <div className="col-lg-6">
                    <div className="newsletter-txt">
                      {/* Section ID */}
                      <span className="section-id">
                        Subscribe to Our Newsletter
                      </span>
                      {/* Title */}
                      <h4 className="h4-xl">
                        Stay up to date with our news, ideas and updates
                      </h4>
                    </div>
                  </div>
                  {/* NEWSLETTER FORM */}
                  <div className="col-lg-6">
                    <form className="newsletter-form">
                      <div className="input-group">
                        <input
                          type="email"
                          autoComplete="off"
                          className="form-control"
                          placeholder="Your email address"
                          required
                          id="s-email"
                        />
                        <span className="input-group-btn">
                          <button
                            type="submit"
                            className="btn btn-md btn-skyblue tra-skyblue-hover"
                          >
                            Subscribe Now
                          </button>
                        </span>
                      </div>
                      {/* Newsletter Form Notification */}
                      <label htmlFor="s-email" className="form-notification" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
export default Terms;
