import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  Background Like this class "bg_chat | bg_fit_01 | bg_fit_02 | bg_mobile | bg_bank_01 | bg_bank_02 | bg_purple_img | bg_sblue_img | bg_selfy | bg_pattern_01 | bg_pattern_02 | bg_pattern_03"
//                      ADD extra class like pt-1/100, pb-1/100, pl-1/100, pr-1/100 and "Your Own Class".
// (2) sub_title = Section sub title
// (3) sec_title = Section title
// (4) sec_description = Section description

const ReviewSlider = (props) => {
  var reviews = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    speed: 2200,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <section
        id="reviews-2"
        className={`rel reviews-section division ${
          !props.section_class == "" ? props.section_class : ""
        }`}
      >
        <div className="container">
          <div className="row">
            {/* TESTIMONIALS TITLE */}
            <div className="col-lg-4">
              <div className="reviews-2-title pc-10">
                {/* Sub title */}
                {!props.sub_title == "" ? (
                  <span className="section-id grey-color">
                    {props.sub_title}
                  </span>
                ) : (
                  ""
                )}
                {/* Title */}
                {!props.sec_title == "" ? (
                  <h2 className="h2-md mb-20">{props.sec_title}</h2>
                ) : (
                  ""
                )}
                {/* Description */}
                {!props.sec_description == "" ? (
                  <p className="p-lg mb-0">{props.sec_description}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* TESTIMONIALS CAROUSEL */}
            <div className="col-lg-8">
              <div className="reviews-2-wrapper">
                {/* Review-Slider-Include */}
                <Slider {...reviews}>
                  {/* TESTIMONIAL #1 */}
                  <div className="review-2 radius-08">
                    {/* App Rating */}
                    <div className="app-rating ico-20 yellow-color">
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                    </div>
                    {/* Title */}
                    <h5 className="h5-sm">Great Pharmacy Delivery App!</h5>
                    {/* Testimonial Text */}
                    <div className="review-2-txt">
                      {/* Text */}
                      <p className="p-lg grey-color">
                        The Capelex Pharmacy App is fantastic! Delivery is
                        quick, and the team is super helpful and friendly. I
                        highly recommend it!
                      </p>
                      {/* Testimonial Author */}
                      <h5 className="h5-xs">- Salma Gabriel</h5>
                    </div>
                  </div>
                  {/* TESTIMONIAL #2 */}
                  <div className="review-2 radius-08">
                    {/* App Rating */}
                    <div className="app-rating ico-20 yellow-color">
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                      <span className="flaticon-star-half-empty" />
                    </div>
                    {/* Title */}
                    <h5 className="h5-sm">Simple and Convenient!</h5>
                    {/* Testimonial Text */}
                    <div className="review-2-txt">
                      {/* Text */}
                      <p className="p-lg grey-color">
                        The Capelex Pharmacy App is easy to use and very useful.
                        I highly recommend it for anyone who needs pharmacy
                        services delivered to their doorstep.
                      </p>
                      {/* Testimonial Author */}
                      <h5 className="h5-xs">- Husna S.</h5>
                    </div>
                  </div>
                  {/* TESTIMONIAL #3 */}
                  <div className="review-2 radius-08">
                    {/* App Rating */}
                    <div className="app-rating ico-20 yellow-color">
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                    </div>
                    {/* Title */}
                    <h5 className="h5-sm">Great delivery service!</h5>
                    {/* Testimonial Text */}
                    <div className="review-2-txt">
                      {/* Text */}
                      <p className="p-lg grey-color">
                        I ordered my medicine through the Capelex Pharmacy app
                        and was really impressed with the delivery service. The
                        delivery was fast and the medication was well-packaged.
                        I will definitely use this app again!
                      </p>
                      {/* Testimonial Author */}
                      <h5 className="h5-xs">- John Shayo</h5>
                    </div>
                  </div>

                  {/* TESTIMONIAL #4 */}
                  <div className="review-2 radius-08">
                    {/* App Rating */}
                    <div className="app-rating ico-20 yellow-color">
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                    </div>
                    {/* Title */}
                    <h5 className="h5-sm">Fast and Convenient!</h5>
                    {/* Testimonial Text */}
                    <div className="review-2-txt">
                      {/* Text */}
                      <p className="p-lg grey-color">
                        I recently used the Capelex Pharmacy App to get my
                        medications delivered and it was such a convenient and
                        hassle-free experience! The delivery was quick and the
                        app was easy to use. Highly recommended!
                      </p>
                      {/* Testimonial Author */}
                      <h5 className="h5-xs">- Tumaini Makole</h5>
                    </div>
                  </div>
                  {/* TESTIMONIAL #5 */}
                  <div className="review-2 radius-08">
                    {/* App Rating */}
                    <div className="app-rating ico-20 yellow-color">
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                    </div>
                    {/* Title */}
                    <h5 className="h5-sm">Great app for medication delivery</h5>
                    {/* Testimonial Text */}
                    <div className="review-2-txt">
                      {/* Text */}
                      <p className="p-lg grey-color">
                        I've been using Capelex Pharmacy's delivery app for a
                        few months now and it's been a game changer. The app is
                        easy to use and has a wide selection of medications
                        available for delivery. The delivery times are also very
                        reasonable and the drivers are always friendly and
                        professional.
                      </p>
                      {/* Testimonial Author */}
                      <h5 className="h5-xs">- Frank Msigwa</h5>
                    </div>
                  </div>
                  {/* TESTIMONIAL #6 */}
                  <div className="review-2 radius-08">
                    {/* App Rating */}
                    <div className="app-rating ico-20 yellow-color">
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                    </div>
                    {/* Title */}
                    <h5 className="h5-sm">Great Services!</h5>
                    {/* Testimonial Text */}
                    <div className="review-2-txt">
                      {/* Text */}
                      <p className="p-lg grey-color">
                        This app has been a game-changer for me. The app is easy
                        to use and the delivery is always prompt and reliable.
                      </p>
                      {/* Testimonial Author */}
                      <h5 className="h5-xs">- Anitha M.</h5>
                    </div>
                  </div>
                  {/* TESTIMONIAL #7 */}
                  <div className="review-2 radius-08">
                    {/* App Rating */}
                    <div className="app-rating ico-20 yellow-color">
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                      <span className="flaticon-star" />
                    </div>
                    {/* Title */}
                    <h5 className="h5-sm">I love this App!</h5>
                    {/* Testimonial Text */}
                    <div className="review-2-txt">
                      {/* Text */}
                      <p className="p-lg grey-color">
                        I have been using Capelex Pharmacy's delivery app for a
                        while now, and I have to say, I'm really impressed. The
                        process is easy and efficient, and my medications always
                        arrive on time.{" "}
                      </p>
                      {/* Testimonial Author */}
                      <h5 className="h5-xs">- Joram Adam</h5>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ReviewSlider;
