import React from 'react';

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  Background Like this class ("bg_chat || bg_fit_01 || bg_fit_02 || bg_mobile || bg_bank_01 || bg_bank_02 || bg_purple_img || bg_sblue_img || bg_selfy || bg_pattern_01 || bg_pattern_02 || bg_pattern_03").
//                      ADD extra class like pt-1/100, pb-1/100, pl-1/100, pr-1/100 and "Your Own Class".
// (2) content_bg = Content section background class like ("teal_gradient || sapphire_gradient || orange_gradient || violet_gradient || purple_gradient || lavender_gradient || crocus_gradient || blue_gradient").
// (2) sub_title = Section content sub title
// (3) title = Section content title

// (4) Section side description we provide 2 Type style:- [(1) ul_content_data, (2) p_content_data] As your structure requirement choice variable
//     Ex:- ul_content_data = {[`Description-1`,`Description-2`,`Description-3`]}
//     Ex:- p_content_data  = {[`Description-1`,`Description-2`,`Description-3`]}

// (3) main_image =  Section main image path set in "IMAGE" folder inside like (`home/img-05.png`) 
// (4) img_Class = Section image parent class


const Info_Content_Img_bg = (props) => {
    return (
        <>
            <section id="content-12" className={`content-section division ${!props.section_class == '' ? props.section_class : ''}`}>
                <div className="container">
                    <div className={`content-12-wrapper ${!props.content_bg == '' ? props.content_bg : ''}`}>
                        <div className="row d-flex align-items-center">
                            {/* TEXT BLOCK */}
                            <div className="col-lg-5">
                                <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                                    
                                    {/* sub title                            */}
                                    {!props.sub_title == '' ? <span className="section-id grey-color">{props.sub_title}</span> : ''}
                                    {/* title */}
                                    {!props.title == '' ? <h2 className="h2-xs">{props.title}</h2> : ''}
                                    {/* description */}

                                    {/*START: P Structure Content Loop ------(1)*/}
                                    {
                                        !props.p_content_data == ''
                                            ?
                                            props.p_content_data.map((item, index) =>
                                                !item == '' ? <p className="p-lg" key={index}>{item}</p> : ''
                                            )
                                            : ''
                                    }
                                    {/*END: P Structure Content Loop ------(1)*/}

                                    {/* && */}

                                    {/*START: Ul Li Structure Content Loop ------(2)*/}
                                    {
                                        !props.ul_content_data == ''
                                            ?
                                            <ul className="simple-list">
                                                {
                                                    props.ul_content_data.map((item, index) =>
                                                        <li className="list-item" key={index}>
                                                            {!item == '' ? <p className="p-lg">{item}</p> : ''}
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                            : ''
                                    }
                                    {/*END: Ul Li Structure Content Loop ------(2)*/}
                                </div>
                            </div>


                            {/* Main Image */}
                            {
                                !props.main_image == ''
                                    ?
                                    <div className="col-lg-7">
                                        <div className={`content-12-img right-column wow fadeInLeft ${!props.img_Class == '' ? props.img_Class : ''}`} data-wow-delay="0.6s">
                                            <img className="img-fluid" src={require(`../assets/images/${props.main_image}`)} alt="content" />
                                        </div>
                                    </div>
                                    : ''
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Info_Content_Img_bg;
