import $ from "jquery";

/*------------------------*/
/*	Navigtion Menu Scroll
/*------------------------*/
	$(window).on('load', function() {		
		var preloader = $('#loader-wrapper');		
		preloader.fadeOut();
		preloader.delay(500).fadeOut('slow');	
	});

/*------------------------*/
/*	Navigtion Menu Scroll
/*------------------------*/
	$(window).on('scroll', function() {
		
		var b = $(window).scrollTop();
		
		if( b > 80 ){		
			$(".wsmainfull").addClass("scroll");
		} else {
			$(".wsmainfull").removeClass("scroll");
		}				
	});


