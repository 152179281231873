import React from 'react';
import WOW from "wowjs";
import Banner10 from '../components/main-banner/Banner10';
import Info_Img_Content from '../components/Info_Img_Content';
import Info_Content_Img from '../components/Info_Content_Img';
import BrandSlider from '../components/Brand-Slider';
import ReviewSlider from '../components/Reviews-Slider';
import Features_Info from '../components/Features_Info';
import Features_Info_9 from '../components/Features_Info_9';
import ProgessbarSec from '../components/Progessbar_sec';
import ChatWithFriends from '../components/ChatWithFriends';
import Pricing from '../components/Pricing';
import Accessible_All_Platform_Banner from '../components/Accessible_All_Platform_Banner';
import Faqs from '../components/Faqs';
import NewsLetter from '../components/NewsLetter';
import Blog from '../components/Blog';
import Download_bgPattern_Banner from '../components/banner/Download_bgPattern_Banner';

class Home10 extends React.Component {
  componentDidMount() {
    const wow = new WOW.WOW(
      { live: false }
    );
    wow.init();
  }
  render() {
    return (
      <>
        {/* PAGE CONTENT */}
        <div id="page" className="page">

          {/* BANNER-TEN */}
          <Banner10
            // All Props information is preset in "components/main-banner/Banner1.jsx"
            section_class={`bg_purple_img bg_shape_03`}
            font_color={`white-color`}
            banner_app_logo={`home/app-logo-fit.png`}
            banner_title={`Healthy Mind In A Healthy Body`}
            app_store_img={`store/appstore-white.png`}
            appstore_link={`https://apps.apple.com/us/app/capelex/id6447323804`} // appstore link
            play_store_img={`store/googleplay-white.png`}
            playstore_link={`https://play.google.com/store/apps/details?id=com.capelexpharmacy.capelex`}
            banner_content_img={`home/hero-10-img.png`}
            version_desciption={`Available for iOS 8 and Android Devices From 5.5`}
          />

          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`wide-50`}
            lft_image={`home/img-16.png`}
            img_Class={``}
            sub_title={`Unlimited Access`}
            title={`Everything you need in one spot`}
            p_content_data={
              [
                `Gravida porta velna vitae auctor congue at magna nihil impedit ligula risus mauris donec a ligula risus`,
                `Aliqum mullam blandit and tempor sapien donec lipsum gravida porta. Velna vitae auctor congue magna impedit ligula risus. Mauris donec ligula an impedit magnis`,
              ]
            }
            button={`true`}
            button_content={`Find Out More`}
            button_bg_and_hover={`btn-tra-grey skyblue-hover`}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          <Features_Info_9
            // All Props information is preset in "Features_Info_9.Jsx"
            section_class={`whitesmoke_shape `}
            sub_title={`Flexible Features`}
            sec_title={`Simple to use, easy to love`}
            p_content_data={
              [
                `Semper lacus cursus porta, feugiat primis and donec ultrice ligula tempus an auctor ipsum nihil mauris lectus enim ipsum sagittis congue`,
                `Gravida porta velna vitae auctor congue magna nihil impedit ligula risus mauris donec ligula`
              ]
            }
            info_data={
              [
                {
                  icon: 'flaticon-clock',
                  title: 'Complete Program',
                  description: 'Porta semper lacus cursus feugiat primis ultrice ligula risus auctor ultrice ociis'
                },
                {
                  icon: 'flaticon-salad',
                  title: 'Meal Plans',
                  description: 'Porta semper lacus cursus feugiat primis ultrice ligula risus auctor ultrice ociis'
                },
                {
                  icon: 'flaticon-exercise',
                  title: 'Logging Simplified',
                  description: 'Porta semper lacus cursus feugiat primis ultrice ligula risus auctor ultrice ociis'
                },
                {
                  icon: 'flaticon-lose-weight',
                  title: 'Stay Motivated',
                  description: 'Porta semper lacus cursus feugiat primis ultrice ligula risus auctor ultrice ociis'
                }
              ]
            }
            icon_color={`skyblue-color`}
          />

          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`wide-60`}
            lft_image={`home/img-18.png`}
            img_Class={``}
            sub_title={`Perfect Integration`}
            title={`Work smarter with powerful features`}
            p_content_data={
              [
                `Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus at egestas sapien vitae. Nemo egestas volute and turpis dolores aliquam quaerat sodales a sapien`,
                `Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus and egestas sapien egestas vitae nemo volute`,
              ]
            }
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`mb-100`}
            sub_title={``}
            title={``}
            icon_content_data={
              [
                {
                  icon: `flaticon-video-player-2`,
                  title: `HD Instructional Videos`,
                  description: `Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at suscipit quaerat ultrice tellus viverra `
                },
                {
                  icon: `flaticon-percentage`,
                  title: `Smart Watch Optimized`,
                  description: `Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at suscipit quaerat ultrice tellus viverra `
                },
                {
                  icon: `flaticon-smartphone-9`,
                  title: `Heart Rate Tracking`,
                  description: `Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at suscipit quaerat ultrice tellus viverra `
                }
              ]
            }
            icon_color={'skyblue-color'}
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            rtl_image={`home/img-17.png`}
            img_Class={`pc-15`}
            bgShape_design_1={''}
            bgShape_design_2={''}
          />


          {/* BRANDS-SLIDER */}
          <BrandSlider
            // All Props information is preset in "Brand-Slider.Jsx"
            section_class={`bg_whitesmoke`}
            sec_title={`You might know Descode from:`}
            brand_images={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          />

          {/* FEATURES-INFO-1 */}
          <Features_Info
            // All Props information is preset in "components/Features_Info.jsx"
            layout_style={2}
            section_class={`wide-50`}
            sec_title={`Get Ready to Be Surprised`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            icon_color={'skyblue-color'}
            info_data={[
              {
                icon: `flaticon-ads`,
                title: `Friendly Interface`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-switch`,
                title: `Powerful Options`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-call`,
                title: `Quick Access`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-analytics-3`,
                title: `Track & Analyze`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-weight`,
                title: `Easy To Follow`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-conversation`,
                title: `Ongoing Support`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              }
            ]}
          />

          {/* PROCESSBAR */}
          <ProgessbarSec
            // All Props information is preset in "components/Progessbar_sec.jsx"
            section_class={`bg_fit_02 wide-100 white-color`}
            sec_title={`Custom workouts on your own time and in your own space`}
          />

          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`wide-80`}
            sub_title={`Work Online and Offline`}
            title={`Stay on track and plan your workout`}
            p_content_data={
              [
                `Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus at egestas sapien vitae nemo egestas volute and turpis dolores aliquam quaerat sodales a sapien`,
              ]
            }
            button={true}
            button_content={`Read The FAQs`}
            button_bg_and_hover={`btn-tra-grey skyblue-hover`}
            rtl_image={`home/img-19.png`}
            img_Class={`pc-20`}
            bgShape_design_1={''}
            bgShape_design_2={''}
          />

          {/* CHAT WITH FRIENDS */}
          <ChatWithFriends
            // All Props information is preset in "components/ChatWithFriends.jsx"		
            section_class={`pb-80`}
            sec_title={`Connect with Like-Minded People`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            sec_img={`home/socials.png`}
            button={true}
            button_title={`See Descode in Action `}
            button_bg_and_hover={`btn-skyblue tra-grey-hover`}
            button_icon={`flaticon-play-button`}
            button_description={`Available on Android, iOS and macOS`}
          />

          {/* REVIEWS-SLIDER*/}
          <ReviewSlider
            // All Props information is preset in "components/Reviews-Slider.jsx"
            section_class={`bg_whitesmoke`}
            sub_title={`Golden Reviews`}
            sec_title={`Our Happy Customers`}
            sec_description={`Aliquam augue suscipit luctus neque purus ipsum neque dolor primis undo tempus, blandit and cursus varius`}
          />

          {/* PRICING */}
          <Pricing
            // All Props information is preset in "components/Pricing.jsx"		
            section_class={`wide-100`}
            sec_title={`Simple and Flexible Pricing`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
          />

          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`bg_dark wide-60 white-color`}
            sub_title={`Totally Optimized`}
            title={`Intuitive features, powerful results`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`,
              ]
            }
            button={``}
            button_bg_and_hover={``}
            button_content={``}
            rtl_image={`home/tablet.png`}
            img_Class={`content-13-img`}
            bgShape_design_1={''}
            bgShape_design_2={''}
          />

          {/* ACCESSIBLE ALL PLATFORM */}
          <Accessible_All_Platform_Banner
            // All Props information is preset in "components/Accessible_All_Platform_Banner.jsx"
            section_class={`pt-100`}
            sec_title={`Accessible for All Platforms`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            sec_img={`home/browsers.png`}
            button={``}
            button_title={``}
            button_bg_and_hover={``}
            button_icon={``}
            button_description={``}
          />

          {/* FAQS */}
          <Faqs
            // All Props information is preset in "components/Faqs.jsx"
            section_class={`wide-100`}
            sub_title={`FAQs`}
            title={`Got Questions? Look Here`}
            content={`Aliquam augue suscipit luctus neque purus ipsum neque dolor primis undo tempus, blandit and cursus varius`}
          />

          {/* NEWSLETTER */}
          <NewsLetter
            // All Props information is preset in "components/NewsLetter.jsx"	
            section_class={``}
            sub_title={`Subscribe to Our Newsletter`}
            title={`Stay up to date with our news, ideas and updates`}
            input_placeholder={``}
            btn_content={`Subscribe Now`}
            button_bg_and_hover={`btn-skyblue tra-skyblue-hover`}
          />

          {/* BLOG */}
          <Blog
            // All Props information is preset in "components/Blog.jsx"	
            section_class={``}
            sec_title={`Our Tips and Latest News`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor `}
            itemStart={10}
            itemEnd={13}
          />

          {/* DOWNLOAD BG PATTERN */}
          <Download_bgPattern_Banner
            // All Props information is preset in "components/Download_bgPattern_Banner.jsx"	
            section_class={`bg_fit_01 pt-100`}
            sec_img={`home/img-15-fit.png`}
            title={`Take your fitness to the next level`}
            content={`Augue egestas volutpat egestas augue purus cubilia laoreet and magna suscipit luctus dolor tempus`}
            img1={'store/appstore-white.png'}
            img2={'store/googleplay-white.png'}
            user={`7.296`}
          />
          
        </div>
        {/* END PAGE CONTENT */}
      </>
    )
  }
}
export default Home10; 