import React from "react";
import { Link } from "react-router-dom";
import BackToTop from "./BackToTop";

import {
  FaLock,
  FaFileContract,
  FaInstagram,
  FaBinoculars,
} from "react-icons/fa";
import {
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaEnvelope,
  FaPhone,
  FaAddressBook,
} from "react-icons/fa";

class Footer extends React.Component {
  render() {
    return (
      <>
        <footer id="footer-3" className="footer division">
          <div className="container">
            <div className="row justify-content-lg-center">
              <div className="col-lg-10">
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <div className="footer-links mb-40">
                      <h5>Contacts</h5>

                      <ul className="foo-links clearfix">
                        <li>
                          <p className="p-md">
                            <FaEnvelope className="mr-2" />
                            <a href="mailto:info@capelexpharmacy.co.tz">
                              Email Now
                            </a>
                          </p>
                        </li>
                        <li>
                          <p className="p-md">
                            <FaPhone className="mr-2" />
                            <a href="tel:+255713931387">+255 747 100 300</a>
                          </p>
                        </li>
                        <li>
                          <p className="p-md">
                            <FaBinoculars className="mr-2" />
                            <Link to="/faqs">Help & Support</Link>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <div className="footer-links mb-40">
                      <h5>Follow Us</h5>
                      <ul className="foo-links clearfix">
                        <li>
                          <p className="p-md">
                            <FaTwitter className="mr-2" />
                            <a
                              href="https://twitter.com/capelexpharmacy"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Twitter
                            </a>
                          </p>
                        </li>
                        <li>
                          <p className="p-md">
                            <FaInstagram className="mr-2" />
                            <a
                              href="https://instagram.com/capelexpharmacy?igshid=YmMyMTA2M2Y="
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Instagram
                            </a>
                          </p>
                        </li>

                        {/* <li>
                          <p className="p-md">
                            <FaFacebookF className="mr-2" />
                            <Link to="#">Facebook</Link>
                          </p>
                        </li> */}
                        <li>
                          <p className="p-md">
                            <FaLinkedinIn className="mr-2" />
                            <a
                              href="https://www.linkedin.com/company/capelex-pharmacy/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Linkedin
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <div className="footer-links mb-40">
                      <h5>Legal</h5>
                      <ul className="foo-links clearfix">
                        <li>
                          <p className="p-md">
                            <FaLock className="mr-2" />
                            <Link to="/Terms">Privacy Policy</Link>
                          </p>
                        </li>
                        <li>
                          <p className="p-md">
                            <FaFileContract className="mr-2" />
                            <Link to="/Terms">Terms & Privacy</Link>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-8 col-lg-3">
                    <div className="footer-store-badges text-right mb-40">
                      <a href="https://apps.apple.com/us/app/capelex/id6447323804" className="store" target="_blank">
                        <img
                          className="appstore"
                          src={require("../assets/images/store/appstore.png")}
                          alt="appstore-badge"
                        />
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=com.capelexpharmacy.capelex" target="_blank"  className="store">
                        <img
                          className="googleplay"
                          src={require("../assets/images/store/googleplay.png")}
                          alt="googleplay-badge"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="bottom-footer footer-copyright text-center">
                  <p>
                    All Rights Reserved by Capelex Pharmacy Limited 2023 &copy;
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>

        {/* Back-To-TOP-Button */}
        <BackToTop />
      </>
    );
  }
}
export default Footer;
