import React from 'react';
import WOW from "wowjs";
import Faq_simple from '../components/Faq_simple';

class Faqs extends React.Component {
  componentDidMount() {
    const wow = new WOW.WOW(
      { live: false }
    );
    wow.init();
  }
  render() {
    return (
      <>
        {/* PAGE CONTENT */}
        <div id="page" className="page">

          {/* FAQS-SIMPLE*/}
          <Faq_simple
            // All Props information is preset in "components/Faq_simple.jsx"	
            section_class={`bg_whitesmoke hero-offset-nav pb-60`}
            sec_title={`Got Questions? Look Here`}
            sec_description={`Our Comprehensive FAQ Section Will Help You Make the Most of Our App`}            
          />

        </div>
        {/* END PAGE CONTENT */}
      </>
    )
  }
}
export default Faqs 