import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/menu.css";
import "../assets/js/menu.js";

class Header extends React.Component {
  render() {
    return (
      <>
        <header id="header" className="header white-menu navbar-dark">
          <div className="header-wrapper">
            <div className="wsmobileheader clearfix">
              <Link to="/" className="smllogo">
                <img
                  src={require("../assets/images/logo/Group 62.png")}
                  alt="mobile-logo"
                />
              </Link>
              <Link to="#" id="wsnavtoggle" className="wsanimated-arrow">
                <span></span>
              </Link>
            </div>
            <div className="wsmainfull menu clearfix">
              <div className="wsmainwp clearfix">
                <div className="desktoplogo">
                  <Link to="/" className="logo-black">
                    <img
                      src={require("../assets/images/logo/Group 62.png")}
                      alt="header-logo"
                    />
                  </Link>
                </div>
                <div className="desktoplogo">
                  <Link to="/" className="logo-white">
                    <img
                      src={require("../assets/images/logo/logo-white.png")}
                      alt="header-logo"
                    />
                  </Link>
                </div>
                <nav className="wsmenu clearfix">
                  <ul className="wsmenu-list nav-rose-hover">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    {/* <li><Link to="/Features">Features</Link></li> */}
                    {/* <li><Link to="#">Pages <span className="wsarrow"></span></Link>
                                            <div className="wsmegamenu clearfix halfmenu">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                    
                                                        <ul className="col-lg-6 link-list">
                                                            <li><Link to="/MoreAppsPage">More Apps</Link></li>
                                                            <li><Link to="/Pricing">Pricing Plans</Link></li>
                                                            <li><Link to="/Reviews">Reviews</Link></li>
                                                            <li><Link to="/Faqs">FAQs Page</Link></li>
                                                        </ul>
                                                        
                                                        <ul className="col-lg-6 link-list">
                                                            <li><Link to="/BlogListing">Blog Listing</Link></li>
                                                            <li><Link to="/SinglePost">Single Blog Post</Link></li>
                                                            <li><Link to="/Terms">Terms & Privacy</Link></li>
                                                            <li><Link to="/Contacts">Contact Us</Link></li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                        </li> */}
                    <li className="nl-simple">
                      <Link to="/Faqs">FAQs</Link>
                    </li>
                    {/* <li><Link to="/Reviews">Reviews</Link></li> */}

                    <li className="nl-simple">
                      <Link to="/Contacts">Contact Us</Link>
                    </li>
                    <li className="nl-simple">
                      <Link
                        to="/contacts"
                        className="btn btn-blue tra-grey-hover last-link"
                      >
                        Let&apos;s Started
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}
export default Header;
