import React, { useState, useEffect } from "react";

const Contacts = () => {
  const [formData, setFormData] = useState({});
  let [data_Storage, setData_Storage] = useState();
  const [errors, setErrors] = useState({});

  // All filled validation
  const validation = () => {
    let nameErr,
      topicErr,
      emailErr = true;

    // choose topic
    if (formData.topic != undefined) {
      if (formData.topic == "0") {
        setErrors((errors) => ({ ...errors, topic: "Please select topic" }));
      } else {
        setErrors((errors) => ({ ...errors, topic: "" }));
        topicErr = false;
      }
    }

    // name
    if (formData.name != undefined) {
      if (formData.name == "") {
        setErrors((errors) => ({ ...errors, name: "Enter your name" }));
      } else {
        var regex = /^[a-zA-Z\s]+$/;
        if (regex.test(formData.name) === false) {
          setErrors((errors) => ({
            ...errors,
            name: "Please enter valid name",
          }));
        } else {
          setErrors((errors) => ({ ...errors, name: "" }));
          nameErr = false;
        }
      }
    }

    // email
    if (formData.email != undefined) {
      if (formData.email == "") {
        setErrors((errors) => ({
          ...errors,
          email: "Enter your email address",
        }));
      } else {
        // Regular expression for basic email validation
        var regex = /^\S+@\S+\.\S+$/;
        if (regex.test(formData.email) === false) {
          setErrors((errors) => ({
            ...errors,
            email: "Please enter a valid email address",
          }));
        } else {
          setErrors((errors) => ({ ...errors, email: "" }));
          emailErr = false;
        }
      }
    }

    // if all input value true then success
    if ((topicErr || nameErr || emailErr) == true) {
      return false;
    } else {
      return true;
    }
  };

  // form submit
  const submitData = (e) => {
    e.preventDefault();
    let isValid = true; // assume that form is validated

    // check all input filled value check through "validation()"" function
    isValid = validation();

    if (formData.topic == undefined) {
      setErrors((errors) => ({ ...errors, topic: "Please select topic..." }));
      isValid = false;
    }
    if (formData.name == undefined) {
      setErrors((errors) => ({ ...errors, name: "Please enter your name..." }));
      isValid = false;
    }
    if (formData.email == undefined) {
      setErrors((errors) => ({ ...errors, email: "Please enter email..." }));
      isValid = false;
    }

    // set form data in LocalStorage
    let localStorage_data =
      JSON.parse(localStorage.getItem("formLocalData_Storage")) || [];

    if (isValid) {
      localStorage_data.push(formData);
      localStorage.setItem(
        "formLocalData_Storage",
        JSON.stringify(localStorage_data)
      );

      setData_Storage(localStorage_data);
      console.table(data_Storage);

      setFormData({});
      alert("Form submit successfully !");

      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    validation();
  }, [formData]);
  return (
    <>
      {/* PAGE CONTENT */}
      <div id="page" className="page">
        {/* CONTACT-2 */}
        <section
          id="contacts-2"
          className="bg_whitesmoke hero-offset-nav pb-50 contacts-section division"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="section-title text-center mb-70">
                  <h2 className="h2-md">How Can We Help?</h2>
                  <p className="p-xl">
                    Thank you for your interest in capelex pharmacy app. If you
                    have any questions, comments, or concerns, please feel free
                    to contact us using any of the methods below:
                  </p>
                  <p className="p-xl ">
                    <h3 className="h2-md">Contact Information:</h3>
                  </p>

                  <p className="p-xl">
                    <ul className="p-x1">
                      <li>
                        Call: <a href="tel:+255713931387">+255 747 100 300</a>
                      </li>

                      <li>
                        Email:{" "}
                        <u>
                          <a href="mailto:info@capelexpharmacy.co.tz">
                            info@capelexpharmacy.co.tz
                          </a>
                        </u>
                      </li>
                    </ul>
                  </p>

                  <p className="p-xl">
                    <h3 className="h2-md">Business Hours:</h3>
                  </p>
                  <p className="p-xl">
                    <ul>
                      <li>Monday - Friday: 24 hours</li>
                      <li>Saturday: 08:00am - 11:00pm EAT</li>
                      <li>Sunday: 08:00am - 11:00pm EAT</li>
                    </ul>
                  </p>
                  <p className="p-xl">
                    <b>
                      Before contacting us, please check our Frequently Asked
                      Questions (FAQ) page to see if your question has already
                      been answered.
                    </b>
                  </p>
                </div>
              </div>
            </div>
            {/* contact */}

            {/* CONTACT FORM */}
          </div>
          {/* GEOMETRIC OVERLAY */}
          <div className="bg_fixed geometric_overlay" />
        </section>
      </div>
    </>
  );
};

export default Contacts;
