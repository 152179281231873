import React from 'react';
import Section_Title from './Section_Title';

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  Background Like this class "bg_chat | bg_fit_01 | bg_fit_02 | bg_mobile | bg_bank_01 | bg_bank_02 | bg_purple_img | bg_sblue_img | bg_selfy | bg_pattern_01 | bg_pattern_02 | bg_pattern_03"
//                      ADD extra class like pt-1/100, pb-1/100, pl-1/100, pr-1/100 and "Your Own Class".
// (2) Sec_title = Section Title
// (3) Sec_description = Section Description
// (4) layout_style = Feature box 4 style present. as you like set show set "1,2,3,4,5,6,7"
//     info_data = All box content add array in object like this key format {icon:` `, title:` `, description:` `}
//     Ex:- layout_style={2}
//        info_data={[
//                      {
//                         icon:`ICON-CLASS-NAME`,
//                         title:`TITLE`,
//                         description:`DESCRIPTION`
//                       }
//                   ]}
// (5) Icon_color = Feature box icon color like (white-color | dark-color | grey-color | lightgrey-color | blue-color | green-color | purple-color | rose-color | sapphire-color | skyblue-color | stateblue-color | violet-color | yellow-color)

const Features_Info = (props) => {
    return (
        <>
            <section className={`features-section division ${!props.section_class == '' ? props.section_class : ''} ${!props.layout_style == '' ? `Layout_Style_${props.layout_style}` : ''}`}>
                {/* id="features-5" Id Convert Class In CSS */}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            {/* Section title */}
                            <Section_Title
                                sub_title={props.seb_title}
                                title={props.sec_title}
                                description={props.sec_description}
                            />
                        </div>
                    </div>
                    {
                        // STYLE-1-START
                        props.layout_style == 1
                            ?
                            <div className="fbox-9-wrapper pc-30">
                                <div className="row">
                                    {/*START: FEATURES BOX Content Loop*/}
                                    {
                                        !props.info_data == ''
                                            ?
                                            props.info_data.map((item, index) => {
                                                return (
                                                    <div id="fb-9-1" className="col-md-6 col-lg-4" key={'content' + index + 1}>
                                                        <div className="fbox-9 mb-40 wow fadeInUp" data-wow-delay="0.4s">
                                                            {!item.title == '' ? <h5 className="h5-sm">{item.title}</h5> : ''}
                                                            {!item.description == '' ? <p className="p-lg">{item.description}</p> : ''}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : ''
                                    }
                                    {/*END: FEATURES BOX Content Loop*/}
                                </div>
                            </div>

                            // STYLE-2-START
                            : props.layout_style == 2
                                ?
                                <div className="fbox-5-wrapper pc-30">
                                    <div className="row">
                                        {/*START: FEATURES BOX Content Loop*/}
                                        {
                                            !props.info_data == ''
                                                ?
                                                props.info_data.map((item, index) => {
                                                    return (
                                                        <div id="fb-5-1" className="col-md-6" key={'content' + index + 1}>
                                                            <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.4s">

                                                                {/* FEATURES box Icon */}
                                                                <div className={`fbox-ico ico-70 ${!props.icon_color == '' ? props.icon_color : ''}`}>
                                                                    <span className={!item.icon == '' ? item.icon : 'ICON_CLASS_NAME_NOT_FOUND'}></span>
                                                                </div>

                                                                <div className="fbox-txt">
                                                                    {/* FEATURES box Title */}
                                                                    {!item.title == '' ? <h5 className="h5-sm">{item.title}</h5> : ''}

                                                                    {/* FEATURES box Description */}
                                                                    {!item.description == '' ? <p className="p-lg">{item.description}</p> : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : ''
                                        }
                                        {/*END: FEATURES BOX Content Loop*/}
                                    </div>
                                </div>

                                // STYLE-3-START
                                : props.layout_style == 3
                                    ?
                                    <div className="fbox-4-wrapper">
                                        <div className="row">
                                            {/*START: FEATURES BOX Content Loop*/}
                                            {
                                                !props.info_data == ''
                                                    ?
                                                    props.info_data.map((item, index) => {
                                                        return (
                                                            <div className="col-md-6" key={'content' + index + 1}>
                                                                <div className="fbox-4 bg_whitesmoke mb-30 wow fadeInUp" data-wow-delay="0.4s">
                                                                    <div className="row d-flex align-items-center m-row">
                                                                        <div className="col-lg-8 m-bottom">
                                                                            <div className="fbox-4-txt">
                                                                                {/* FEATURES box Title */}
                                                                                {!item.title == '' ? <h5 className="h5-sm">{item.title}</h5> : ''}

                                                                                {/* FEATURES box Description */}
                                                                                {!item.description == '' ? <p className="p-md">{item.description}</p> : ''}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 m-top">
                                                                            {/* FEATURES box Icon */}
                                                                            <div className={`fbox-4-ico ico-100 text-center ${!props.icon_color == '' ? props.icon_color : ''}`}>
                                                                                <span className={!item.icon == '' ? item.icon : 'ICON_CLASS_NAME_NOT_FOUND'}></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    : ''
                                            }
                                            {/*END: FEATURES BOX Content Loop*/}
                                        </div>
                                    </div>

                                    // STYLE-4-START
                                    : props.layout_style == 4
                                        ?
                                        <div className="fbox-3-wrapper">
                                            <div className="row">
                                                {/*START: FEATURES BOX Content Loop*/}
                                                {
                                                    !props.info_data == ''
                                                        ?
                                                        props.info_data.map((item, index) => {
                                                            return (
                                                                <div className={`col-md-6 col-lg-3 mt-3 mb-3 ${index <= 3 ? 'mt-0' : ''}`} key={'content' + index + 1}>
                                                                    <div className="fbox-3 bg_white wow fadeInUp" data-wow-delay="0.4s">
                                                                        {/* FEATURES box Icon */}
                                                                        <div className={`fbox-3-ico ico-65 ${!props.icon_color == '' ? props.icon_color : 'grey-color'}`}>
                                                                            <span className={!item.icon == '' ? item.icon : 'ICON_CLASS_NAME_NOT_FOUND'}></span>
                                                                        </div>
                                                                        {/* FEATURES box Title */}
                                                                        {!item.title == '' ? <h5 className="h5-sm">{item.title}</h5> : ''}
                                                                        {/* FEATURES box Description */}
                                                                        {!item.description == '' ? <p className="p-md">{item.description}</p> : ''}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        : ''
                                                }
                                                {/*END: FEATURES BOX Content Loop*/}
                                            </div>
                                        </div>

                                        // STYLE-5-START
                                        : props.layout_style == 5
                                            ?
                                            <div className="fbox-6-wrapper">
                                                <div className="row">
                                                    {/*START: FEATURES BOX Content Loop*/}
                                                    {
                                                        !props.info_data == ''
                                                            ?
                                                            props.info_data.map((item, index) => {
                                                                return (
                                                                    <div className="col-md-6 col-lg-4" key={'content' + index + 1}>
                                                                        <div className="fbox-6 pc-15 mb-50 wow fadeInUp" data-wow-delay="0.4s">
                                                                            {/* FEATURES box Icon & Title */}
                                                                            <div className={`fbox-6-title ico-40 clearfix`}>
                                                                                <span className={`${!item.icon == '' ? item.icon : 'ICON_CLASS_NAME_NOT_FOUND'} ${!props.icon_color == '' ? props.icon_color : 'skyblue-color'}`}></span>
                                                                                {!item.title == '' ? <h5 className="h5-sm">{item.title}</h5> : ''}
                                                                            </div>
                                                                            {/* FEATURES box Description */}
                                                                            {!item.description == '' ? <p className="p-lg">{item.description}</p> : ''}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            : ''
                                                    }
                                                    {/*END: FEATURES BOX Content Loop*/}
                                                </div>
                                            </div>

                                            // STYLE-6-START
                                            : props.layout_style == 6
                                                ?
                                                <div className="fbox-1-wrapper pc-30 text-center">
                                                    <div className="row">
                                                        {/*START: FEATURES BOX Content Loop*/}
                                                        {
                                                            !props.info_data == ''
                                                                ?
                                                                props.info_data.map((item, index) => {
                                                                    return (
                                                                        <div className="col-md-4" key={'content' + index + 1}>
                                                                            <div className="fbox-1 mb-40 wow fadeInUp" data-wow-delay="0.4s">
                                                                                {/* FEATURES box Icon & Title */}
                                                                                <div className={`fbox-1-ico ico-80 ${!props.icon_color == '' ? props.icon_color : 'skyblue-color'}`}>
                                                                                    <span className={`${!item.icon == '' ? item.icon : 'ICON_CLASS_NAME_NOT_FOUND'}`}></span>
                                                                                </div>

                                                                                {/* Title */}
                                                                                {!item.title == '' ? <h5 className="h5-sm">{item.title}</h5> : ''}

                                                                                {/* FEATURES box Description */}
                                                                                {!item.description == '' ? <p className="p-lg">{item.description}</p> : ''}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                                : ''
                                                        }
                                                        {/*END: FEATURES BOX Content Loop*/}
                                                    </div>
                                                </div>

                                                // STYLE-7-START
                                                : props.layout_style == 7
                                                    ?
                                                    <div className="fbox-7-wrapper">
                                                        <div className="row">
                                                            {/*START: FEATURES BOX Content Loop*/}
                                                            {
                                                                !props.info_data == ''
                                                                    ?
                                                                    props.info_data.map((item, index) => {
                                                                        return (
                                                                            <div className={`col-sm-6 col-md-3 mt-5 mb-5 ${index <= 3 ? 'mt-0' : ''}`} key={'content' + index + 1}>
                                                                                <div className="fbox-7 text-center wow fadeInUp" data-wow-delay="0.4s">                                                                                    
                                                                                    {/* FEATURES box Icon */}
                                                                                    <div className={`fbox-7-ico ico-75 ${!props.icon_color == '' ? props.icon_color : 'skyblue-color'}`}>
                                                                                        <span className={`${!item.icon == '' ? item.icon : 'ICON_CLASS_NAME_NOT_FOUND'}`}></span>
                                                                                    </div>
                                                                                    {/* FEATURES box description */}
                                                                                    {!item.title == '' ? <h5 className="h5-xs">{item.title}</h5> : ''}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                    : ''
                                                            }
                                                            {/*END: FEATURES BOX Content Loop*/}
                                                        </div>
                                                    </div>

                                                    // STYLE-8-START
                                                    : props.layout_style == 8
                                                        ?
                                                        <div className="bg_white wide-50 fbox-11-wrapper division">
                                                            <div className="row">
                                                                {/*START: FEATURES BOX Content Loop*/}
                                                                {
                                                                    !props.info_data == ''
                                                                        ?
                                                                        props.info_data.map((item, index) => {
                                                                            return (
                                                                                <div id="fb-11-1" className="col-lg-4" key={'content' + index + 1}>
                                                                                    <div className="fbox-11 mb-40 wow fadeInUp" data-wow-delay="0.4s">
                                                                                        {/* FEATURES box Icon */}
                                                                                        <div className={`fbox-11-ico ico-60 ${!props.icon_color == '' ? props.icon_color : 'skyblue-color'}`}>
                                                                                            <span className={`${!item.icon == '' ? item.icon : 'ICON_CLASS_NAME_NOT_FOUND'}`}></span>
                                                                                        </div>
                                                                                        {/* FEATURES box Title */}
                                                                                        <h5 className="h5-md">{item.title}</h5>
                                                                                        {/* FEATURES box Description */}
                                                                                        <p className="p-lg">{item.description}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        : ''
                                                                }
                                                                {/*END: FEATURES BOX Content Loop*/}
                                                            </div>
                                                        </div>
                                                        :
                                                        // STYLE-NOT-AVAILABLE
                                                        <div className='row'>
                                                            <div className="col-12 text-center wide-100">
                                                                <h5 className="h5-xs">layout_style is not Available please select "1 to 8"</h5>
                                                            </div>
                                                        </div>

                    }
                </div>
            </section>
        </>
    );
}

export default Features_Info;
