import React from "react";
import WOW from "wowjs";
import Banner1 from "../components/main-banner/Banner1";
import Download_bgImage_Banner from "../components/banner/Download_bgImage_Banner";
import InfoBannerLeft from "../components/InfoBanner_left";
import Features_Info from "../components/Features_Info";
import ReviewSlider from "../components/Reviews-Slider";
import BrandSlider from "../components/Brand-Slider";
import NewsLetter from "../components/NewsLetter";
import Faqs from "../components/Faqs";
import Blog from "../components/Blog";
import MoreApps from "../components/MoreApps";
import Accessible_All_Platform_Banner from "../components/Accessible_All_Platform_Banner";
import Info_Img_Content from "../components/Info_Img_Content";
import Info_Content_Img from "../components/Info_Content_Img";

class Home extends React.Component {
  componentDidMount() {
    const wow = new WOW.WOW({ live: false });
    wow.init();
  }
  render() {
    return (
      <>
        {/* PAGE CONTENT */}
        <div id="page" className="page">
          {/* BANNER-ONE */}
          <Banner1
            // All Props information is preset in "components/main-banner/Banner1.jsx"
            section_class={`bg_purple_img bg_shape_03`}
            font_color={`white-color`}
            // banner_app_logo={`home/shade-logo.png`}
            banner_title={`Capelex Pharmacy`}
            banner_description={`Efficient, trusted medicine delivery service. Say goodbye to queues and traffic. Get your prescriptions hassle-free with us.`} // banner description
            app_store_img={`store/appstore.png`}
            appstore_link={`https://apps.apple.com/us/app/capelex/id6447323804`} // appstore link
            play_store_img={`store/googleplay.png`}
            playstore_link={`https://play.google.com/store/apps/details?id=com.capelexpharmacy.capelex`}
            banner_content_img={`home/hero image.png`}
          />

          {/* FEATURES-INFO*/}
          <Features_Info
            // All Props information is preset in "components/Features_Info.jsx"
            layout_style={3}
            section_class={`features-2 pt-50`}
            sec_title={`Quick, Easy and Secure`}
            sec_description={`Secure, speedy, and seamless medicine delivery at your fingertips is Simplified with our quick and easy app`}
            info_data={[
              {
                title: `Quick Access`,
                description: `Allows you to find the medicines you need quickly and easily. You can browse by category.`,
                icon: `flaticon-call`,
              },
              {
                title: `Messaging`,
                description: `Easy to communicate with our pharmacist and delivery drivers.  you can quickly send and receive messages about your prescriptions`,
                icon: `flaticon-chat`,
              },
              {
                title: `Alerts & Notifications`,
                description: `Receive real-time updates on your order status and delivery updates, you can stay informed accordingly`,
                icon: `flaticon-message`,
              },
              {
                title: `Upload Prescription`,
                description: `Easy-to-use prescription upload feature, you can send photos of your prescriptions directly for a faster and more convenient ordering process.`,
                icon: `flaticon-photo`,
              },
            ]}
            icon_color={"skyblue-color"}
          />

          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`wide-70`}
            lft_image={`home/img-new.png`}
            img_Class={``}
            sub_title={`Unlimited Access`}
            title={`Stay healthy with us`}
            p_content_data={[
              `Our Unlimited Services mean that you can order as much medication as you need without any restrictions or limitations. You will never have to worry about running out of your prescription or over-the-counter medications again. `,
              `Our app offers fast and reliable delivery, so you can receive your medications right at your doorstep.`,
            ]}
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* FEATURES-INFO */}
          <Features_Info
            // All Props information is preset in "components/Features_info.jsx"
            layout_style={5}
            section_class={`pb-50`}
            sec_title={`Get Ready to Be Surprised`}
            sec_description={`Our pharmacy delivery app offers more than just the convenience of having your medications delivered to your doorstep. Get ready to be surprised by the multitude of functionalities it provides.`}
            info_data={[
              {
                icon: "flaticon-check",
                title: "Order",
                description:
                  "Easily place your order for medication, vitamins, or other health products directly from the app. Say goodbye to waiting in long lines at the pharmacy.",
              },
              {
                icon: "flaticon-contactless",
                title: "Cash on Delivery",
                description:
                  "With our secure payment options, you can choose to pay for your order in cash upon delivery. No need to worry about online transactions or payment processing.",
              },
              {
                icon: "flaticon-ads",
                title: "Timeslot",
                description:
                  "Select a convenient delivery timeslot that works for you. Whether you need your medication in the morning, afternoon, or evening, we've got you covered.",
              },
              {
                icon: "flaticon-switch",
                title: "Multibranch",
                description:
                  "With multiple branches, we can deliver your medications from the nearest branch to your location. This ensures a speedy delivery and reduces waiting times.",
              },
              {
                icon: "flaticon-pantone",
                title: "Dark and Light Mode",
                description:
                  "Choose the mode that suits your preferences. Whether you prefer a darker or lighter interface, our app offers both options for your convenience.",
              },
              {
                icon: "flaticon-chat-4",
                title: "Realtime Chat",
                description:
                  "Need to consult with a pharmacist or have a question about your medication? Our app features a real-time chat function, allowing you to easily connect with our pharmacists from the comfort of your home.",
              },
            ]}
            icon_color={"stateblue-color"}
          />

          {/* REVIEWS-SLIDER*/}
          <ReviewSlider
            // All Props information is preset in "components/Reviews-Slider.jsx"
            section_class={`bg_aliceblue`}
            sub_title={`Why People Use Capelex Pharmacy`}
            sec_title={`Our Happy Customers`}
            sec_description={`Here's what some of our customers have to say about their experience with Capelex Pharmacy app`}
          />

          {/* DOWNLOAD BG_IMAGE BANNER*/}
          <Download_bgImage_Banner
            // All Props information is preset in "components/Download_bg_img.jsx"
            section_class={"bg_selfy"}
            font_color={`white-color`}
            sub_title={"Download Capelex Pharmacy App Today"}
            title={"Get Your Medications Delivered to Your Doorstep"}
            description={`Download our pharmacy delivery app today and experience the convenience of having your medications delivered straight to your doorstep. Say goodbye to long lines and rushed trips to the pharmacy.`}
            img1={"store/appstore-white.png"}
            img2={"store/googleplay-white.png"}
          />

          {/* FAQS */}
          <Faqs
            // All Props information is preset in "components/Faqs.jsx"
            section_class={``}
            sub_title={`FAQs`}
            title={`Common Questions`}
            content={`Our Comprehensive FAQ Section Will Help You Make the Most of Our App`}
          />

          {/* BRANDS-SLIDER */}
          <BrandSlider
            // All Props information is preset in "Brand-Slider.Jsx"
            section_class={`bg_whitesmoke `}
            sec_title={`Amazing Brands`}
            brand_images={[
              1, 2, 3, 4, 5, 6, 8, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
            ]}
          />

          {/* NEWSLETTER */}
          <NewsLetter
            // All Props information is preset in "components/NewsLetter.jsx"
            section_class={``}
            sub_title={`Subscribe to Our Newsletter`}
            title={`Stay up to date with our news, ideas and updates`}
            input_placeholder={``}
            btn_content={`Subscribe Now`}
            button_bg_and_hover={`btn-rose tra-rose-hover`} //Like btn-tra-grey skyblue-hover
          />
        </div>
        {/* END PAGE CONTENT */}
      </>
    );
  }
}
export default Home;
