import React from 'react';
import WOW from "wowjs";
import Features_Info from '../components/Features_Info';
import Banner4 from '../components/main-banner/Banner4';
import Info_Img_Content from '../components/Info_Img_Content';
import Info_Content_Img from '../components/Info_Content_Img';
import Accessible_All_Platform_Banner from '../components/Accessible_All_Platform_Banner';
import Reviews from '../components/Reviews';
import Pricing from '../components/Pricing';
import Brand from '../components/Brand';
import Faqs from '../components/Faqs';
import Download_bgPattern_Banner from '../components/banner/Download_bgPattern_Banner';
import Info_Content_Img_bg from '../components/Info_Content_Img_bg';

class Home4 extends React.Component {
  componentDidMount() {
    const wow = new WOW.WOW(
      { live: false }
    );
    wow.init();
  }
  render() {
    return (
      <>
        {/* PAGE CONTENT*/}
        <div id="page" className="page">

          {/* BANNER-FOUR */}
          <Banner4
            // All Props information is preset in "components/main-banner/Banner4.jsx"
            section_class={``}
            font_color={`white-color`}
            banner_title={`Get fast and secure access to all your medicines`}
            app_store_img={`store/appstore.png`}
            appstore_link={`https://apps.apple.com/us/app/capelex/id6447323804`} // appstore link
            play_store_img={`store/googleplay.png`}
            playstore_link={`https://play.google.com/store/apps/details?id=com.capelexpharmacy.capelex`}
            info_description={`Available for iOS and Android Devices`}
            banner_content_img={`home/hero-4-img.png`}
          />

          {/* FEATURES-INFO */}
          <Features_Info
            // All Props information is preset in "components/Features_Info.jsx"
            layout_style={5}
            section_class={`wide-50`}
            sec_title={``}
            sec_description={``}
            icon_color={`skyblue-color`}
            info_data={[
              {
                title: `File Manager`,
                icon: `flaticon-email`,
                description: `Porta semper lacus and cursus risus undo it a ultrice sodales tempus egestas sapien`
              },
              {
                title: `Contacts Manager`,
                icon: `flaticon-users`,
                description: `Porta semper lacus and cursus risus undo it a ultrice sodales tempus egestas sapien`
              },
              {
                title: `Make Ringtones`,
                icon: `flaticon-alarm`,
                description: `Porta semper lacus and cursus risus undo it a ultrice sodales tempus egestas sapien`
              },
              {
                title: `Convert Media Files`,
                icon: `flaticon-roundabout`,
                description: `Porta semper lacus and cursus risus undo it a ultrice sodales tempus egestas sapien`
              },
              {
                title: `Files Sharing`,
                icon: `flaticon-harddisk`,
                description: `Porta semper lacus and cursus risus undo it a ultrice sodales tempus egestas sapien`
              },
              {
                title: `Storage & Backup`,
                icon: `flaticon-harddisk-1`,
                description: `Porta semper lacus and cursus risus undo it a ultrice sodales tempus egestas sapien`
              }
            ]}
          />

          <Info_Content_Img_bg
            // All Props information is preset in "Info_Content_Img_bg.Jsx"
            section_class={`mb-60`}
            content_bg={`lavender_gradient`}
            sub_title={``}
            title={`Work smarter with powerful automation`}
            p_content_data={
              [
                `Quaerat sodales sapien euismod purus at blandit and purus ipsum primis and cubilia laoreet augue a luctus magna dolor luctus mauris pretium a sapien egestas luctus`,
                `Fringilla risus, luctus mauris auctor a purus euismod orci pretium purus pretium undo ligula rutrum tempor magna dolor`
              ]
            }
            main_image={`home/img-12.png`}
          />

        
          {/* FEATURES-INFO*/}
          <Features_Info
            // All Props information is preset in "components/Features_Info.jsx"
            layout_style={3}
            section_class={`pb-100`}
            sec_title={`Get Ready to Be Surprised`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            info_data={
              [
                {
                  title: `Quick Access`,
                  description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
                  icon: `flaticon-call`
                },
                {
                  title: `Powerful Options`,
                  description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
                  icon: `flaticon-tools`
                },
                {
                  title: `Multiple Devices`,
                  description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
                  icon: `flaticon-smartphone-8`
                },
                {
                  title: `Battery Saving`,
                  description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
                  icon: `flaticon-phone-1`
                }
              ]
            }
            icon_color={''}
          />

        
          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`wide-100`}
            lft_image={`home/img-03.png`}
            img_Class={``}
            sub_title={`Beauty of Simplicity`}
            title={`Beautiful, award-winning design`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium
                ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo
                pretium a purus mauris`
              ]
            }
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

       

          {/* REVIEWS SIMPLE */}
          <Reviews
            // All Props information is preset in "components/Reviews.jsx"
            section_class={`bg_aliceblue wide-60`}
            section_title={`Why Customers Love Us`}
            section_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor `}
            app_store_img={`store/appstore.png`}
            appstore_link={``}
            play_store_img={`store/googleplay.png`}
            playstore_link={``}
            app_version_desc={`Available for iOS 8 and Android Devices From 5.5`}
          />

  

         

          {/* BRANDS-2 */}
          <Brand
            // All Props information is preset in "components/Brand.jsx"
            section_class={`bg_whitesmoke `}
            sec_title={`Amazing brands:`}
            brand_images={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          />

          {/* FAQS */}
          <Faqs
            // All Props information is preset in "components/Faqs.jsx"
            section_class={``}
            sub_title={`FAQs`}
            title={`Got Questions? Look Here`}
            content={`Aliquam augue suscipit luctus neque purus ipsum neque dolor primis undo tempus, blandit and cursus varius`}
          />


          {/* DOWNLOAD BG PATTERN */}
          <Download_bgPattern_Banner
            // All Props information is preset in "components/Download_bgPattern_Banner.jsx"	
            section_class={'bg_purple_img pt-100'}
            sec_img={`home/img-15.png`}
            title={`Save your time & effort with our app`}
            content={`Augue egestas volutpat egestas augue purus cubilia laoreet and magna suscipit luctus dolor tempus`}
            img1={'store/appstore.png'}
            img2={'store/googleplay.png'}
            user={``}
          />

        </div>
        {/* END PAGE CONTENT */}
      </>
    )
  }
}
export default Home4; 