import React from 'react';
import WOW from "wowjs";
import Banner5 from '../components/main-banner/Banner5';
import Features_Info from '../components/Features_Info';
import Info_Img_Content from '../components/Info_Img_Content';
import Info_Content_Img from '../components/Info_Content_Img';
import InfoBannerLeft from '../components/InfoBanner_left';
import BrandSlider from '../components/Brand-Slider';
import Accessible_All_Platform_Banner from '../components/Accessible_All_Platform_Banner';
import Download_bgImage_Banner from '../components/banner/Download_bgImage_Banner';
import Pricing from '../components/Pricing';
import Reviews from '../components/Reviews';
import Faqs from '../components/Faqs';
import NewsLetter from '../components/NewsLetter';
import Blog from '../components/Blog';
import Download_bgPattern_Banner_Center from '../components/banner/Download_bgPattern_Banner_Center';
import CounterSection from '../components/Counter_Section';

class Home5 extends React.Component {
  componentDidMount() {
    const wow = new WOW.WOW(
      { live: false }
    );
    wow.init();

  }
  render() {
    return (
      <>
        {/* PAGE CONTENT */}
        <div id="page" className="page">

          {/* MAIN-BANNER-FIVE */}
          <Banner5
            // All Props information is preset in "components/main-banner/Banner1.jsx"
            section_class={``}
            // Left content
            lft_title={`Simple And Beautiful Email Productivity`}
            lft_description={`Feugiat primis ligula and risus auctor a rhoncus ultrice risus in ultrice purus`}
            lft_button_content={`Watch the Overview`}
            lft_button_link={``}
            lft_button_icon={`flaticon-play-button`}

            //Center content image
            banner_content_img={`home/hero-5-img.png`}

            // Right Content
            rtl_title={`Make Choice And Have Fun!`}
            app_store_img={`store/appstore.png`}
            appstore_link={``} // appstore link
            play_store_img={`store/googleplay.png`}
            playstore_link={``}
            user_review={`7.296`}

          />

          {/* FEATURES-INFO */}
          <Features_Info
            // All Props information is preset in "components/Features_Info.jsx"
            layout_style={6}
            section_class={`wide-60`}
            sec_title={``}
            sec_description={``}
            icon_color={`stateblue-color`}
            info_data={[
              {
                icon: `flaticon-mobile-phone`,
                title: `Quick Access`,
                description: `Porta semper lacus a cursus, feugiat primis ultrice ligula and risus auctor ultrice and ociis ligula blandit`
              },
              {
                icon: `flaticon-chat-4`,
                title: `Smart Notifications`,
                description: `Porta semper lacus a cursus, feugiat primis ultrice ligula and risus auctor ultrice and ociis ligula blandit`
              },
              {
                icon: `flaticon-spam`,
                title: `Spam Protection`,
                description: `Porta semper lacus a cursus, feugiat primis ultrice ligula and risus auctor ultrice and ociis ligula blandit`
              }
            ]}
          />

          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`pb-60`}
            sub_title={`Unlimited Access`}
            title={`Stay connected with friends easily`}
            p_content_data={
              [
                `Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus at egestas sapien vitae. Nemo egestas volute and turpis dolores aliquam quaerat sodales a sapien`,
                `Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus and egestas sapien egestas vitae nemo volute`
              ]
            }
            icon_color={'stateblue-color'}
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            rtl_image={`home/img-07.png`}
            img_Class={``}
            bgShape_design_1={''}
            bgShape_design_2={''}
          />

          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`pb-100`}
            lft_image={`home/img-02.png`}
            img_Class={``}
            sub_title={`Perfect Integration`}
            title={`Lightning fast and super powerful`}
            p_content_data={
              [
                `Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus and egestas sapien egestas vitae nemo volute`,
              ]
            }
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* FEATURES-INFO */}
          <Features_Info
            // All Props information is preset in "components/Features_Info.jsx"
            layout_style={8}
            section_class={`wide-100 bg_whitesmoke `}
            sec_title={`Ready to Try Descode?`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            icon_color={`white-color bg_slateblue`}
            info_data={[
              {
                icon: `flaticon-user-1`,
                title: `Multiple Accounts`,
                description: `Porta semper lacus a cursus, feugiat primis ultrice ligula and risus auctor ultrice and ociis ligula blandit`
              },
              {
                icon: `flaticon-smiley`,
                title: `Stickers & Emojis`,
                description: `Porta semper lacus a cursus, feugiat primis ultrice ligula and risus auctor ultrice and ociis ligula blandit`
              },
              {
                icon: `flaticon-image`,
                title: `Share Files & Media`,
                description: `Porta semper lacus a cursus, feugiat primis ultrice ligula and risus auctor ultrice and ociis ligula blandit`
              }
            ]}
          />

          {/* INFO BANNER-LEFT */}
          <InfoBannerLeft
            // All Props information is preset in "components/InfoBanner_left.jsx"						
            section_bg_class={`bg_white`}
            font_color={``}
            sec_img={`home/img-04.png`}
            sub_title={`Fastest Messaging`}
            title={`Send text, voice, picture messages more faster`}
            content={`Aliqum mullam blandit and tempor sapien donec ipsum gravida porta. Velna vitae auctor congue magna impedit ligula risus. Mauris donec ligula and magnis undo sapien sagittis sapien pretium enim gravida purus ligula`}
          />

          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`wide-100`}
            sub_title={``}
            title={``}
            icon_content_data={
              [
                {
                  icon: `flaticon-video-player`,
                  title: `Text, Voice & Video Calls`,
                  description: `Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at suscipit quaerat ultrice tellus viverra `
                },
                {
                  icon: `flaticon-smiley`,
                  title: `Stickers, Emojis, Themes`,
                  description: `Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at suscipit quaerat ultrice tellus viverra `
                },
                {
                  icon: `flaticon-image`,
                  title: `Share Files & Media`,
                  description: `Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at suscipit quaerat ultrice tellus viverra `
                }
              ]
            }
            icon_color={'stateblue-color'}
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            rtl_image={`home/img-05.png`}
            img_Class={``}
            bgShape_design_1={'bg_el_01'}
            bgShape_design_2={'bg_el_04'}
          />


          {/* FEATURES-INFO*/}
          <Features_Info
            // All Props information is preset in "components/Features_Info.jsx"
            layout_style={3}
            section_class={`pb-100`}
            sec_title={`More Features to Expect`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            info_data={
              [
                {
                  title: `Dark & Light Modes`,
                  description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
                  icon: `flaticon-paint-brush`
                },
                {
                  title: `Group Messaging`,
                  description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
                  icon: `flaticon-smartphone-8`
                },
                {
                  title: `Voice Assistant`,
                  description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
                  icon: `flaticon-voice-message-1`
                },
                {
                  title: `Strong Encryption`,
                  description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
                  icon: `flaticon-mobile-1`
                }
              ]
            }
            icon_color={''}
          />

          {/* COUNTER SECTION */}
          <CounterSection
            // All Props information is preset in "components/Counter_Section.jsx"	
            section_class={`pt-70 pb-30 bg_purple_img white-color`}
            sec_title={``}
            sec_description={``}
            Counter_data={
              [
                {
                  counter_number: 78,
                  counter_title: 'Faster Access'
                },
                {
                  counter_number: 52,
                  counter_title: 'App Productivity'
                },
                {
                  counter_number: 82,
                  counter_title: 'Secure Access'
                },
                {
                  counter_number: 44,
                  counter_title: 'Battery Saving'
                }
              ]
            }
            countText_color={``}
          />


          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`wide-100`}
            lft_image={`home/img-03.png`}
            img_Class={``}
            sub_title={`Beauty of Simplicity`}
            title={`Beautiful, award-winning design`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`
              ]
            }
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`pb-60`}
            sub_title={`Totally Optimized`}
            title={`Intuitive features, powerful results`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`
              ]
            }
            icon_color={''}
            button={true}
            button_content={`Read The FAQs`}
            button_bg_and_hover={`btn-tra-grey rose-hover`}
            button_icon={``}
            rtl_image={`home/tablet.png`}
            img_Class={`content-13-img`}
            bgShape_design_1={'bg_el_01'}
            bgShape_design_2={'bg_el_05'}
          />

          {/* BRANDS-SLIDER */}
          <BrandSlider
            // All Props information is preset in "Brand-Slider.Jsx"
            section_class={`bg_whitesmoke`}
            sec_title={`You might know Descode from:`}
            brand_images={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          />

          {/* ACCESSIBLE ALL PLATFORM */}
          <Accessible_All_Platform_Banner
            // All Props information is preset in "components/Accessible_All_Platform_Banner.jsx"
            section_class={`wide-100`}
            sec_title={`Accessible for All Platforms`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            sec_img={`home/browsers.png`}
            button={true} // true/false
            button_content={`See Descode in Action `}
            button_bg_and_hover={`btn-tra-grey rose-hover`} // btn-tra-grey skyblue-hover
            button_icon={`flaticon-play-button`} // flaticon-play-button
            button_description={`Available on Android, iOS and macOS`}
          />

          {/* DOWNLOAD BG_IMAGE BANNER*/}
          <Download_bgImage_Banner
            // All Props information is preset in "components/Download_bg_img.jsx"
            section_class={'bg_chat'}
            font_color={`white-color`}
            sub_title={'Built For Speed'}
            title={'Share text, voice, photos, videos, GIFs and files for free'}
            description={`Aliquam a augue suscipit, luctus neque at purus ipsum neque dolor primis libero tempus, blandit posuere orci auctor purus euismod an aliquam quaerat purus`}
            img1={'store/appstore.png'}
            img2={'store/googleplay.png'}
          />

          {/* PRICING */}
          <Pricing
            // All Props information is preset in "components/Pricing.jsx"		
            section_class={`wide-100 `}
            sec_title={`Simple and Flexible Pricing`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
          />

          {/* REVIEWS SIMPLE */}
          <Reviews
            // All Props information is preset in "components/Reviews.jsx"
            section_class={`bg_aliceblue wide-60`}
            section_title={`Why Customers Love Us`}
            section_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor `}
            app_store_img={`store/appstore.png`}
            appstore_link={``}
            play_store_img={`store/googleplay.png`}
            playstore_link={``}
            app_version_desc={`Available for iOS 8 and Android Devices From 5.5`}
          />

          {/* FAQS */}
          <Faqs
            // All Props information is preset in "components/Faqs.jsx"
            section_class={``}
            sub_title={`FAQs`}
            title={`Common Questions`}
            content={`Aliquam augue suscipit luctus neque purus ipsum neque dolor primis undo tempus, blandit and cursus varius`}
          />

          {/* NEWSLETTER */}
          <NewsLetter
            // All Props information is preset in "components/NewsLetter.jsx"	
            section_class={`pb-20`}
            sub_title={`Subscribe to Our Newsletter`}
            title={`Stay up to date with our news, ideas and updates`}
            input_placeholder={``}
            btn_content={`Subscribe Now`}
            button_bg_and_hover={`btn-rose tra-rose-hover`} //Like btn-tra-grey skyblue-hover					
          />

          {/* BLOG */}
          <Blog
            // All Props information is preset in "components/Blog.jsx"
            section_class={`wide-60`}
            sec_title={`Our Tips and Latest News`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor `}
            itemStart={3}
            itemEnd={6}
          />

          {/* DOWNLOAD BG IMAGE */}
          <Download_bgPattern_Banner_Center
            // All Props information is preset in "components/Download_bgImage_Banner.jsx"		
            section_class={`bg_image bg_sblue_img pt-100`}
            sub_title={`Built For Speed`}
            title={`Share text, voice, photos, videos, GIFs and files for free`}
            description={`Aliquam a augue suscipit, luctus neque at purus ipsum neque dolor primis libero tempus, blandit posuere orci auctor purus euismod an aliquam quaerat purus`}
            img1={'store/appstore.png'}
            img1_link={``}
            img2={'store/googleplay.png'}
            img2_link={``}
            sec_img={`home/img-20.png`}
          />
        </div>
        {/* END PAGE CONTENT */}
      </>
    )
  }
}
export default Home5; 