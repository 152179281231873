import React from 'react';
import Section_Title from './Section_Title';
import Question_List_Data from "../assets/json/Question_List_Data";

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  Theme classes like("pt-100 | mb-100 & Background class find in style.css") & your own class
// (2) sec_title =  Section sub title
// (3) sec_description =  Section title
// (4) Question_List_Data =  All qustion list data come from JSON file(assets/json/Question_List_Data.json).
//                  (1) item.question = Your question
//                  (2) item.answer = Your answer

const Faq_simple = (props) => {
  return (
    <>
      <section id="faqs-2" className={`faqs-section division ${!props.section_class == '' ? props.section_class : ''}`}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* section Title */}
              <Section_Title
                title={props.sec_title}
                description={props.sec_description}
              />
            </div>
          </div>

          <div className="faqs-2-questions pc-15">
            <div className="row">
              {/* START: Json file data loop*/}
              {
                !Question_List_Data == ''
                  ?
                  Question_List_Data.map((item, index) =>
                    <div className="col-lg-6" key={'questionList_' + index}>
                      <div className="questions-holder pc-5">
                        <div className="question wow fadeInUp" data-wow-delay="0.4s">
                          {/* question */}
                          {!item.question == '' ? <h5 className="h5-sm">{item.question}</h5> : ''}
                          {/* answer */}
                          {!item.answer == '' ? <p className="p-lg">{item.answer} </p> : ''}
                        </div>
                      </div>
                    </div>
                  )
                  : ''
              }              
              {/* END: Json file data loop*/}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="more-questions text-center mt-40">
                <h5 className="h5-sm"><span className="flaticon-check" />
                  Have more questions? <a href="/contacts" className="skyblue-color">Ask your question here</a>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faq_simple;
